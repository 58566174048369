* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

.Home {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
}
.Home-container {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.Home-container .Chat-max992 {
  display: none;
  position: absolute;
  left: 15px;
  bottom: 75px;
  cursor: pointer;
  z-index: 1000;
}
.Home-container .Chat-max992 .Chat {
  width: 40px;
  height: 40px;
}
.Home-container .Chat-max992 .Chat img {
  width: 100%;
  height: 100%;
}
@media (min-width: 1500px) {
  .Home-container .Chat-max992 .Chat {
    width: 55px;
    height: 55px;
    left: 20px;
    bottom: 20px;
  }
}
@media (max-width: 992px) {
  .Home-container .Chat-max992 {
    display: inline;
  }
}
@media (max-width: 992px) {
  .Home-container {
    display: block;
    overflow: hidden;
    padding: 0;
  }
}
@media (min-width: 1500px) {
  .Home-container {
    padding: 0;
  }
}

.Loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.LoadingMain {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(238, 238, 238);
  opacity: 0.5;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoadingMain > div {
  width: 50%;
  height: 100%;
}
@media (max-width: 992px) {
  .LoadingMain > div {
    width: 100%;
    height: 45%;
  }
}

.DesignPanel {
  padding: 0;
  width: 25%;
  height: 100%;
  margin: 0;
  background-color: #ffffff;
  position: relative;
}
.DesignPanel-container {
  height: 100%;
  padding: 0;
}
@media (max-width: 992px) {
  .DesignPanel-container {
    display: block;
    padding: 0;
    width: 100%;
    height: 100%;
  }
}
.DesignPanel-tools {
  padding: 0;
  height: 100%;
}
.DesignPanel-tools-title-1 {
  display: flex;
  height: 8%;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 20px;
  margin: 0 0 5px 0;
}
.DesignPanel-tools-title-1 h3 {
  font-size: 20px;
  width: max-content;
  height: 100%;
  font-weight: 700;
  line-height: 28.8px;
  color: #5C5E62;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-title-1 h3 {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .DesignPanel-tools-title-1 h3 {
    font-size: 24px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-title-1 h3 {
    font-size: 27px;
  }
}
.DesignPanel-tools-title-1-line {
  width: calc(33.3333333333% - 30px);
  height: 1px;
  background-color: #BFBFBF;
}
@media (max-width: 992px) {
  .DesignPanel-tools-title-1-line {
    width: calc(33.3333333333% - 0px);
  }
}
@media (max-width: 768px) {
  .DesignPanel-tools-title-1-line {
    width: calc(33.3333333333% - 40px);
  }
}
@media (max-width: 992px) {
  .DesignPanel-tools-title-1 {
    padding: 0;
  }
}
.DesignPanel-tools-typeRose {
  padding: 20px 25px;
  height: auto;
}
.DesignPanel-tools-typeRose-container {
  padding: 15px 0;
  height: 100%;
}
.DesignPanel-tools-typeRose-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DesignPanel-tools-typeRose-title span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #757980;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-typeRose-title span {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .DesignPanel-tools-typeRose-title span {
    font-size: 16px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-typeRose-title span {
    font-size: 17px;
    padding: 10px 0;
  }
}
.DesignPanel-tools-typeRose-content .Rose-type {
  margin-top: 16px;
  width: 100%;
}
.DesignPanel-tools-typeRose-content .Rose-type-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px 10px;
  margin-bottom: 14px;
  cursor: pointer;
  transition: all 0.3s;
}
.DesignPanel-tools-typeRose-content .Rose-type-item-image {
  border-radius: 50%;
  border: 1px solid #BFBFBF;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s;
}
.DesignPanel-tools-typeRose-content .Rose-type-item-image img {
  width: 100%;
  height: auto;
}
@media (max-width: 992px) {
  .DesignPanel-tools-typeRose-content .Rose-type-item-image {
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-typeRose-content .Rose-type-item-image {
    width: 45px;
    height: 45px;
  }
}
.DesignPanel-tools-typeRose-content .Rose-type-item .ActiveRose {
  border: 2px solid #000000 !important;
  background-color: #EFBDC2 !important;
}
.DesignPanel-tools-typeRose-content .Rose-type-item-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  padding-right: 5px;
}
@media (max-width: 992px) {
  .DesignPanel-tools-typeRose-content .Rose-type-item-text {
    font-size: 14px;
    font-weight: 400;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-typeRose-content .Rose-type-item-text {
    font-size: 15px;
  }
}
.DesignPanel-tools-typeRose-content .Rose-type-item:hover {
  background-color: #F8E8E9 !important;
}
.DesignPanel-tools-typeRose-content .Rose-type-item:hover .Rose-type-item-image {
  background-color: #EFBDC2 !important;
}
.DesignPanel-tools-colorRose {
  padding: 0px 25px 20px 25px;
  height: auto;
}
.DesignPanel-tools-colorRose-container {
  padding: 15px 0;
  height: 100%;
}
.DesignPanel-tools-colorRose-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DesignPanel-tools-colorRose-title span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #757980;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-colorRose-title span {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .DesignPanel-tools-colorRose-title span {
    font-size: 16px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-colorRose-title span {
    font-size: 17px;
    padding: 10px 0;
  }
}
.DesignPanel-tools-colorRose-content {
  margin-top: 16px;
}
.DesignPanel-tools-colorRose .Rose-color {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}
.DesignPanel-tools-colorRose .Rose-color > div {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 37px;
  height: 37px;
}
.DesignPanel-tools-colorRose .Rose-color > div:hover {
  background-color: #D7D8D9;
}
@media (max-width: 992px) {
  .DesignPanel-tools-colorRose .Rose-color > div {
    width: 38px;
    height: 38px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-colorRose .Rose-color > div {
    width: 40px;
    height: 40px;
  }
}
.DesignPanel-tools-colorRose .Rose-color-item {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
@media (max-width: 992px) {
  .DesignPanel-tools-colorRose .Rose-color-item {
    width: 31px;
    height: 31px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-colorRose .Rose-color-item {
    width: 33px;
    height: 33px;
  }
}
.DesignPanel-tools-colorRose .Rose-color-check {
  position: absolute;
  font-size: 26px;
}
.DesignPanel-tools-colorRose .Rose-color-check::before {
  font-weight: 900 !important;
}
.DesignPanel-tools-colorRose .Rose-color img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .DesignPanel-tools-colorRose .Rose-color {
    gap: 13px;
  }
}
@media (max-width: 992px) {
  .DesignPanel-tools-colorRose {
    padding-top: 0;
    margin-bottom: 16px;
  }
}
.DesignPanel-tools-title-2 {
  display: flex;
  height: 8%;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 20px;
  margin: 0 0 5px 0;
}
.DesignPanel-tools-title-2 h3 {
  font-size: 20px;
  width: max-content;
  height: 100%;
  font-weight: 700;
  line-height: 28.8px;
  color: #5C5E62;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-title-2 h3 {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .DesignPanel-tools-title-2 h3 {
    font-size: 20px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-title-2 h3 {
    font-size: 27px;
  }
}
.DesignPanel-tools-title-2-line {
  width: calc(33.3333333333% - 50px);
  height: 1px;
  background-color: #BFBFBF;
}
@media (max-width: 992px) {
  .DesignPanel-tools-title-2-line {
    width: calc(33.3333333333% - 20px);
  }
}
@media (max-width: 768px) {
  .DesignPanel-tools-title-2-line {
    width: calc(33.3333333333% - 50px);
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-title-2-line {
    font-size: 30px;
    width: calc(33.3333333333% - 77px);
  }
}
@media (max-width: 1200px) {
  .DesignPanel-tools-title-2 {
    padding: 0 5px;
  }
}
.DesignPanel-tools-title-3 {
  display: flex;
  height: 8%;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 20px;
  margin: 0 0 5px 0;
}
.DesignPanel-tools-title-3 h3 {
  font-size: 20px;
  width: max-content;
  height: 100%;
  font-weight: 700;
  line-height: 28.8px;
  color: #5C5E62;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-title-3 h3 {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .DesignPanel-tools-title-3 h3 {
    font-size: 20px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-title-3 h3 {
    font-size: 27px;
  }
}
.DesignPanel-tools-title-3-line {
  width: calc(33.3333333333% - 20px);
  height: 1px;
  background-color: #BFBFBF;
}
@media (max-width: 992px) {
  .DesignPanel-tools-title-3-line {
    width: calc(33.3333333333% - 0px);
  }
}
@media (max-width: 768px) {
  .DesignPanel-tools-title-3-line {
    width: calc(33.3333333333% - 20px);
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-title-3-line {
    font-size: 30px;
    width: calc(33.3333333333% - 37px);
  }
}
.DesignPanel-tools::-webkit-scrollbar {
  width: 5px;
}
.DesignPanel-tools::-webkit-scrollbar-track {
  background: #D0D1D2;
  display: none;
}
.DesignPanel-tools::-webkit-scrollbar-thumb {
  background: #5C5E62;
  display: none;
}
@media (max-width: 992px) {
  .DesignPanel-tools {
    position: relative;
    padding-bottom: 0;
  }
}
.DesignPanel-price {
  padding-bottom: 4px;
  height: auto;
  position: sticky;
  bottom: 0;
}
@media (max-width: 992px) {
  .DesignPanel-price {
    margin-top: 6px;
    left: 0;
    bottom: 0;
    padding-bottom: 0px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-price {
    bottom: 0;
  }
}
@media (max-width: 1200px) {
  .DesignPanel {
    width: 30%;
  }
}
@media (max-width: 992px) {
  .DesignPanel {
    display: block;
    padding: 10px 0 0 0;
    width: 100%;
    flex-wrap: nowrap;
    height: 55%;
  }
}
@media (min-width: 1500px) {
  .DesignPanel {
    width: 21%;
  }
}

.Price {
  background-color: #FFFFFF;
}
.Price-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border: 1px solid #F8E8E9;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.168627451);
  background-color: #FFFFFF;
  border-radius: 8px;
}
@media (min-width: 1500px) {
  .Price-container {
    height: 100%;
    padding: 15px 25px;
  }
}
@media (max-width: 992px) {
  .Price-container {
    padding: 6px 20px;
  }
}
.Price-number p {
  color: #A7A7A7;
  font-size: 12px;
  font-weight: 700;
  line-height: 19.2px;
  text-align: left;
  margin-bottom: 2px;
}
@media (max-width: 992px) {
  .Price-number p {
    font-size: 12px;
  }
}
@media (min-width: 1500px) {
  .Price-number p {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
  }
}
.Price-number span {
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: left;
}
@media (max-width: 992px) {
  .Price-number span {
    font-size: 18px;
  }
}
@media (min-width: 1500px) {
  .Price-number span {
    font-size: 20px;
    font-weight: 700;
  }
}
.Price-add button {
  border: 0;
  border-radius: 4px;
  background-color: #323232;
  box-shadow: none !important;
  color: #FFFFFF;
  padding: 6px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.Price-add button:hover {
  color: #FFFFFF;
  background: linear-gradient(85.77deg, #323232 -3.33%, #A47378 39.48%, #3B3B3B 99.81%);
  box-shadow: none;
}
.Price-add button:active {
  background: #323232;
}
@media (max-width: 992px) {
  .Price-add button {
    font-size: 13px;
    padding: 5px 30px;
  }
}
@media (min-width: 1500px) {
  .Price-add button {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 30px;
  }
}
@media (max-width: 992px) {
  .Price {
    padding: 0px;
    width: 100%;
    box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.3294117647);
    border-radius: 6px;
  }
}
@media (min-width: 1500px) {
  .Price {
    height: 100%;
  }
}

.Rose-material {
  margin-bottom: 50px;
}
.Rose-material-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Rose-material-title span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #757980;
}
@media (max-width: 1200px) {
  .Rose-material-title span {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .Rose-material-title span {
    font-size: 16px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .Rose-material-title span {
    font-size: 17px;
    padding: 10px 0;
  }
}
.Rose-material-list {
  margin-top: 10px;
}
.Rose-material-list-item {
  margin-bottom: 10px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.09px;
  cursor: pointer;
}
.Rose-material-list-item .Tick {
  width: 11px;
  height: 26px;
  margin-right: 4px;
}
.Rose-material-list-item .Tick img {
  width: 100%;
  height: auto;
}
@media (min-width: 1500px) {
  .Rose-material-list-item .Tick {
    width: 13px;
    height: 27px;
  }
}
.Rose-material-list-item span {
  font-size: 12px;
  line-height: 16.8px;
}
@media (max-width: 992px) {
  .Rose-material-list-item span {
    font-size: 13px;
  }
}
@media (min-width: 1500px) {
  .Rose-material-list-item span {
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .Rose-material-list-item {
    width: 100%;
    height: 54px;
  }
}
@media (min-width: 1500px) {
  .Rose-material-list-item {
    width: 100%;
    height: 54px;
  }
}
.Rose-Boxes-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Rose-Boxes-title span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #757980;
}
@media (max-width: 1200px) {
  .Rose-Boxes-title span {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .Rose-Boxes-title span {
    font-size: 16px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .Rose-Boxes-title span {
    font-size: 17px;
    padding: 10px 0;
  }
}
.Rose-Boxes-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 7px;
  margin-top: 20px;
}
.Rose-Boxes-list-item {
  width: 85px;
  height: 85px;
  border: 1px solid #9C9C9C;
  border-radius: 4px;
  background-color: #F8E8E9;
  cursor: pointer;
  position: relative;
}
.Rose-Boxes-list-item img {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .Rose-Boxes-list-item {
    width: 75px;
    height: 75px;
  }
}
@media (min-width: 1500px) {
  .Rose-Boxes-list-item {
    width: 119px;
    height: 119px;
  }
}
.Rose-Boxes-list-empty {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Rose-Boxes-list-empty img {
  width: 60px;
  height: 60px;
}
@media (min-width: 1500px) {
  .Rose-Boxes-list-empty img {
    width: 70px;
    height: 70px;
  }
}
.Rose-Boxes-list-empty span {
  font-size: 12px;
  font-weight: 700;
}
.Rose-Boxes-list::-webkit-scrollbar {
  width: 5px;
}
.Rose-Boxes-list::-webkit-scrollbar-track {
  background: #D0D1D2;
}
.Rose-Boxes-list::-webkit-scrollbar-thumb {
  background: #5C5E62;
}
@media (max-width: 992px) {
  .Rose-Boxes-list {
    gap: 16px;
  }
}
@media (max-width: 992px) {
  .Rose-Boxes {
    display: block;
  }
}

.Rose-quantity {
  margin-bottom: 30px;
}
.Rose-quantity-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Rose-quantity-title span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #757980;
}
@media (max-width: 1200px) {
  .Rose-quantity-title span {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .Rose-quantity-title span {
    font-size: 16px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .Rose-quantity-title span {
    font-size: 17px;
    padding: 10px 0;
  }
}
.Rose-quantity-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0px;
  column-gap: 12px;
  row-gap: 10px;
}
.Rose-quantity-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: auto;
}
.Rose-quantity-list-item-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 37px;
  height: 37px;
  padding: 5px;
}
.Rose-quantity-list-item-image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
@media (max-width: 992px) {
  .Rose-quantity-list-item-image {
    width: 45px;
    height: 45px;
  }
}
@media (min-width: 1500px) {
  .Rose-quantity-list-item-image {
    width: 45px;
    height: 45px;
  }
}
.Rose-quantity-list-item-text {
  font-size: 8px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: center;
  color: #000000;
  margin-bottom: 0;
}
@media (max-width: 992px) {
  .Rose-quantity-list-item-text {
    font-size: 10px;
  }
}
@media (min-width: 1500px) {
  .Rose-quantity-list-item-text {
    font-size: 11px;
  }
}
@media (max-width: 992px) {
  .Rose-quantity-list {
    column-gap: 20px;
    row-gap: 10px;
  }
}
@media (min-width: 1500px) {
  .Rose-quantity-list {
    column-gap: 20px;
    margin-top: 16px;
  }
}

.DesignPanel-tools-designRose {
  padding: 0 25px;
  height: auto;
}
.DesignPanel-tools-designRose-container {
  padding: 15px 0;
  height: 100%;
  margin-top: 16px;
}
.DesignPanel-tools-designRose .Add {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
}
.DesignPanel-tools-designRose .Add-item {
  width: calc(33.3333333333% - 5px);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #9C9C9C;
  border-radius: 8px;
  padding: 0px 7px;
  cursor: pointer;
}
.DesignPanel-tools-designRose .Add-item-icon {
  width: 35px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DesignPanel-tools-designRose .Add-item-icon img {
  width: 100%;
  height: auto;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-designRose .Add-item-icon {
    width: 30px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose .Add-item-icon {
    width: 36px;
  }
}
.DesignPanel-tools-designRose .Add-item-text {
  font-size: 9px;
  font-weight: 500;
  line-height: 14px;
  color: #393C41;
  margin-left: 12px;
  transition: background-color 0.3s;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-designRose .Add-item-text {
    font-size: 9px;
    font-weight: 500;
  }
}
@media (max-width: 1200px) {
  .DesignPanel-tools-designRose .Add-item-text {
    font-size: 12px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose .Add-item-text {
    font-size: 12px;
    line-height: 16px;
  }
}
.DesignPanel-tools-designRose .Add-item:hover {
  background-color: #D7D8D9;
  transition: background-color 0.3s;
}
@media (max-width: 1200px) {
  .DesignPanel-tools-designRose .Add-item {
    padding: 7px;
  }
}
@media (max-width: 992px) {
  .DesignPanel-tools-designRose .Add-item {
    padding: 7px 10px;
    height: 50px;
  }
}
@media (min-width: 1500px) {
  .DesignPanel-tools-designRose .Add-item {
    height: 55px;
  }
}

.AddText {
  padding: 15px 0;
}
.AddText-container {
  border: 1px dashed #DF818A;
  border-radius: 8px;
  padding: 5px 15px;
}
.AddText .New {
  padding: 10px 0;
  height: auto;
}
.AddText .New-text {
  width: 100%;
}
.AddText .New-text-item {
  width: 100%;
  position: relative;
  margin-top: 4px;
}
.AddText .New-text-item-title {
  position: absolute;
  left: 15px;
  top: 9px;
  font-size: 12px;
  font-weight: 600;
  color: #5C5E62;
}
@media (max-width: 992px) {
  .AddText .New-text-item-title {
    font-size: 12px;
    top: 9.5px;
  }
}
@media (min-width: 1500px) {
  .AddText .New-text-item-title {
    font-size: 14px;
    top: 9.5px;
  }
}
.AddText .New-text-item-textarea {
  width: 100%;
  height: 37px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #9C9C9C;
  padding: 7.7px 5px 0px 55px;
  resize: none;
  border-radius: 2.27px;
  font-size: 12px;
}
.AddText .New-text-item-textarea:focus {
  outline: 0;
}
.AddText .New-text-item-textarea::placeholder {
  font-size: 11px;
  color: #9C9C9C;
  padding-top: 2.2px;
}
@media (max-width: 992px) {
  .AddText .New-text-item-textarea {
    width: 100%;
  }
}
@media (min-width: 1500px) {
  .AddText .New-text-item-textarea {
    padding-left: 60px;
    padding-top: 10px;
    height: 42px;
  }
}
.AddText .New-textFont {
  margin-top: 16px;
  display: inline-flex;
  align-items: center;
  position: relative;
}
.AddText .New-textFont label {
  font-size: 11px;
  font-weight: 400;
  line-height: 16.8px;
  color: #171A20;
  margin-right: 10px;
}
@media (min-width: 1500px) {
  .AddText .New-textFont label {
    font-size: 13px;
  }
}
.AddText .New-textFont select {
  cursor: pointer;
  padding: 2px 5px;
  height: 30px;
  width: 130px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #5C5E62;
  border-radius: 8px;
  font-size: 11px;
  font-weight: 400;
  line-height: 14.4px;
  color: #5C5E62;
  display: flex;
  align-items: center;
}
.AddText .New-textFont select option {
  padding: 4px 15px;
  border-radius: 2px !important;
  color: #5C5E62;
}
.AddText .New-textFont select:focus {
  outline: 0;
}
@media (max-width: 992px) {
  .AddText .New-textFont select {
    font-size: 12px;
  }
}
@media (min-width: 1500px) {
  .AddText .New-textFont select {
    font-size: 13px;
    height: 33px;
  }
}
.AddText .New-textFont::after {
  content: "\f282";
  font-family: "bootstrap-icons";
  position: absolute;
  bottom: 7px;
  right: 5%;
  pointer-events: none;
  font-size: 10px;
  font-weight: 900;
  color: #000000;
}
@media (min-width: 1500px) {
  .AddText .New-textFont::after {
    bottom: 8px;
  }
}
.AddText .New-textFont-loading {
  position: absolute;
  right: -31%;
  width: max-content;
  height: 40px;
}
.AddText .New-textFont-loading > div {
  position: relative;
}
.AddText .New-textColor {
  margin-top: 16px;
  height: auto;
}
.AddText .New-textColor label {
  font-size: 11px;
  font-weight: 400;
  line-height: 16.8px;
  color: #171A20;
  margin-right: 10px;
}
@media (min-width: 1500px) {
  .AddText .New-textColor label {
    font-size: 13px;
  }
}
.AddText .New-textColor-list {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
}
.AddText .New-textColor-list-item {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 37px;
  height: 37px;
}
.AddText .New-textColor-list-item:hover {
  background-color: #D7D8D9;
}
.AddText .New-textColor-list-item > div {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
@media (max-width: 992px) {
  .AddText .New-textColor-list-item > div {
    width: 31px;
    height: 31px;
  }
}
@media (min-width: 1500px) {
  .AddText .New-textColor-list-item > div {
    width: 33px;
    height: 33px;
  }
}
.AddText .New-textColor-list-item-check {
  position: absolute;
  font-size: 26px;
}
.AddText .New-textColor-list-item-check::before {
  font-weight: 900 !important;
}
@media (max-width: 992px) {
  .AddText .New-textColor-list-item {
    width: 38px;
    height: 38px;
  }
}
@media (min-width: 1500px) {
  .AddText .New-textColor-list-item {
    width: 40px;
    height: 40px;
  }
}
.AddText .New-textColor-list img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.AddImage {
  padding: 15px 0;
}
.AddImage-container {
  padding: 15px 0 0 0;
  height: 100%;
}
.AddImage-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddImage-title span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #757980;
}
@media (max-width: 1200px) {
  .AddImage-title span {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .AddImage-title span {
    font-size: 16px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .AddImage-title span {
    font-size: 17px;
    padding: 10px 0;
  }
}
.AddImage-content {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px dashed #DF818A;
  border-radius: 8px;
  padding: 5px 15px;
  height: auto;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddImage-content .Image {
  padding: 5px 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
.AddImage-content .Image-drag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.AddImage-content .Image-drag img {
  width: 60px;
  height: auto;
}
@media (min-width: 1500px) {
  .AddImage-content .Image-drag img {
    width: 70px;
  }
}
.AddImage-content .Image-drag-browseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  height: 30px;
  background-color: #FFFFFF;
  color: #5C5E62;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: center;
  border: 1px solid #9C9C9C;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}
.AddImage-content .Image-drag-browseBtn:hover {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
.AddImage-content .Image-drag-browseBtn:active {
  background-color: #B04953;
  color: #FFFFFF;
  transition: all 0.3s;
}
@media (min-width: 1500px) {
  .AddImage-content .Image-drag-browseBtn {
    height: 40px;
    font-size: 15px;
    font-weight: 500;
  }
}
.AddImage-content .Image-view {
  width: 60%;
  height: auto;
  position: relative;
}
.AddImage-content .Image-view img {
  width: 100%;
  height: auto;
}
.AddImage-content .Image-view-close {
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: #FFFFFF;
  padding: 0 7px;
  cursor: pointer;
}
.AddImage-content .Image-view-close i {
  font-size: 22px;
}
@media (max-width: 992px) {
  .AddImage-content .Image-view {
    width: 50%;
  }
}
.AddImage .ImageSize {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.AddImage .ImageSize-icon {
  padding: 2px 6px;
  background: rgba(239, 189, 194, 0.5019607843);
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}
.AddImage .ImageSize-icon i {
  font-size: 22px;
}
.AddImage .ImageSize-icon i::after {
  font-weight: 900 !important;
}
@media (min-width: 1500px) {
  .AddImage .ImageSize-icon i {
    font-family: 24px;
  }
}
@media (min-width: 1500px) {
  .AddImage .ImageSize-icon {
    width: 40px;
    height: 40px;
  }
}
.AddImage .ImageSize-text {
  font-size: 16px;
}

.ImageRules {
  padding: 15px 0;
  height: auto;
  margin-top: 16px;
  border: 1px dashed #DF818A;
  border-radius: 8px;
}
.ImageRules-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ImageRules-title span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #757980;
}
@media (max-width: 1200px) {
  .ImageRules-title span {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .ImageRules-title span {
    font-size: 16px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .ImageRules-title span {
    font-size: 17px;
    padding: 10px 0;
  }
}
.ImageRules-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ImageRules-container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.ImageRules-container .ImageRules-row1 {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  height: auto;
  margin-bottom: 10px;
}
.ImageRules-container .ImageRules-row1-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ImageRules-container .ImageRules-row1-item img {
  width: 50px;
  height: auto;
}
@media (min-width: 1500px) {
  .ImageRules-container .ImageRules-row1-item img {
    width: 60px;
  }
}
.ImageRules-container .ImageRules-row1-item div {
  width: 100%;
}
.ImageRules-container .ImageRules-row1-item p {
  margin-bottom: 0;
  font-size: 9px;
  font-weight: 400;
  text-align: center;
  width: 96%;
  margin-bottom: 0;
  margin-top: 5px;
}
@media (min-width: 1500px) {
  .ImageRules-container .ImageRules-row1-item p {
    font-size: 11px;
  }
}
@media (max-width: 992px) {
  .ImageRules-container .ImageRules-row1 {
    width: 100%;
    justify-content: space-evenly;
  }
}
@media (min-width: 1500px) {
  .ImageRules-container .ImageRules-row1 {
    width: 100%;
    height: auto;
  }
}
.ImageRules-container .ImageRules-row2 {
  margin: 8px;
  padding: 5px 2px 5px 2px;
  height: auto;
  overflow: auto;
}
.ImageRules-container .ImageRules-row2-title {
  font-size: 11px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0;
  text-align: justify;
}
.ImageRules-container .ImageRules-row2-text {
  padding-left: 25px;
  margin-bottom: 4px;
  text-align: justify;
}
.ImageRules-container .ImageRules-row2-text li {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5;
  list-style-type: disc;
  text-align: justify;
}
.ImageRules-container .ImageRules-row2-text li u {
  text-decoration: none;
  border-bottom: 1px solid #000000;
}
.ImageRules-container .ImageRules-row2::-webkit-scrollbar {
  width: 5px;
}
.ImageRules-container .ImageRules-row2::-webkit-scrollbar-track {
  background: #D0D1D2;
}
.ImageRules-container .ImageRules-row2::-webkit-scrollbar-thumb {
  background: #5C5E62;
}
@media (min-width: 1500px) {
  .ImageRules-container {
    height: 84%;
  }
}
.ImageRules-confirmBtn {
  width: 95%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B04953;
  color: #ffffff;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s;
}
.ImageRules-confirmBtn input {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.ImageRules-confirmBtn:hover {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.3s;
}
.ImageRules-confirmBtn:active {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.3s;
}
@media (min-width: 1500px) {
  .ImageRules-confirmBtn {
    height: 50px;
    font-size: 15px;
    font-weight: 700;
  }
}
.ImageRules::-webkit-scrollbar {
  width: 5px;
}
.ImageRules::-webkit-scrollbar-track {
  background: #D0D1D2;
}
.ImageRules::-webkit-scrollbar-thumb {
  background: #5C5E62;
}

.ImageUpload {
  padding: 15px 0;
  height: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 16px;
  border: 1px dashed #DF818A;
  border-radius: 8px;
}
.ImageUpload-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ImageUpload-title span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #757980;
}
@media (max-width: 1200px) {
  .ImageUpload-title span {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .ImageUpload-title span {
    font-size: 16px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .ImageUpload-title span {
    font-size: 17px;
    padding: 10px 0;
  }
}
.ImageUpload-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.ImageUpload-container > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ImageUpload-container .ImageUpload-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ImageUpload-container .ImageUpload-view-box {
  position: relative;
  width: 90%;
  height: 200px;
}
.ImageUpload-container .ImageUpload-view-editHandler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
}
.ImageUpload-container .ImageUpload-view-editHandler button {
  padding: 2px 6px;
  background: rgba(239, 189, 194, 0.5019607843);
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}
.ImageUpload-container .ImageUpload-view-editHandler button i {
  font-size: 22px;
}
.ImageUpload-container .ImageUpload-view-editHandler button i::after {
  font-weight: 900 !important;
}
@media (max-width: 992px) {
  .ImageUpload-container .ImageUpload-view-editHandler button i {
    font-size: 18px;
  }
}
@media (min-width: 1500px) {
  .ImageUpload-container .ImageUpload-view-editHandler button i {
    font-family: 24px;
  }
}
@media (min-width: 1500px) {
  .ImageUpload-container .ImageUpload-view-editHandler button {
    width: 40px;
    height: 40px;
  }
}
.ImageUpload-container .ImageUpload-view-editHandler button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.ImageUpload-container .ImageUpload-view-editHandler .progress-bar-container {
  position: relative;
  width: 70%;
  height: 7px;
  background: #D0D1D2;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 5px;
}
@media (max-width: 992px) {
  .ImageUpload-container .ImageUpload-view-editHandler .progress-bar-container {
    height: 5px;
  }
}
.ImageUpload-container .ImageUpload-view-editHandler .progress-bar {
  position: absolute;
  height: 100%;
  background: #B04953;
  transition: width 0.2s ease-in-out;
}
.ImageUpload-container .ImageUpload-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
}
.ImageUpload-container .ImageUpload-edit-tryAgain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ImageUpload-container .ImageUpload-edit-tryAgain p {
  cursor: pointer;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 700;
  border-bottom: 2px solid #393C41;
  color: #393C41;
}
@media (min-width: 1500px) {
  .ImageUpload-container .ImageUpload-edit-tryAgain p {
    font-size: 15px;
  }
}
.ImageUpload-container .ImageUpload-edit-tryAgain input {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
@media (min-width: 1500px) {
  .ImageUpload-container {
    height: 84%;
  }
}
.ImageUpload-confirmBtn {
  width: 95%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #B04953;
  color: #ffffff;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s;
}
.ImageUpload-confirmBtn input {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.ImageUpload-confirmBtn:hover {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.3s;
}
.ImageUpload-confirmBtn:active {
  background-color: #ffffff;
  color: #000000;
  transition: all 0.3s;
}
@media (min-width: 1500px) {
  .ImageUpload-confirmBtn {
    height: 50px;
    font-size: 15px;
    font-weight: 700;
  }
}

.AddClipart {
  padding: 15px 0;
}
.AddClipart-container {
  padding: 15px 0 0 0;
  height: 100%;
}
.AddClipart-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddClipart-title span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #757980;
}
@media (max-width: 1200px) {
  .AddClipart-title span {
    font-size: 15px;
  }
}
@media (max-width: 992px) {
  .AddClipart-title span {
    font-size: 16px;
    font-weight: 700;
  }
}
@media (min-width: 1500px) {
  .AddClipart-title span {
    font-size: 17px;
    padding: 10px 0;
  }
}
.AddClipart-content {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 1px dashed #DF818A;
  border-radius: 8px;
  padding: 5px 5px;
  height: 300px;
}
.AddClipart-content .New-Clipart {
  width: 100%;
  padding: 5px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.AddClipart-content .New-Clipart-category {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: 16px;
}
.AddClipart-content .New-Clipart-category-title {
  font-size: 11px;
  font-weight: 400;
  margin-right: 10px;
  color: #171A20;
}
@media (min-width: 1500px) {
  .AddClipart-content .New-Clipart-category-title {
    font-size: 13px;
  }
}
.AddClipart-content .New-Clipart-category-list {
  width: 150px;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 400;
  color: #171A20;
}
.AddClipart-content .New-Clipart-category-list:focus {
  outline: 0;
}
@media (min-width: 1500px) {
  .AddClipart-content .New-Clipart-category-list {
    font-size: 13px;
    height: 33px;
  }
}
.AddClipart-content .New-Clipart-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: 0;
  margin-right: 0;
}
.AddClipart-content .New-Clipart-list-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 3px;
}
.AddClipart-content .New-Clipart-list-item-image {
  width: 100%;
  min-height: 30px;
  height: auto;
}
.AddClipart-content .New-Clipart-list-item-image img {
  width: 100%;
  height: auto;
}
.AddClipart-content .New-Clipart-list-item-close {
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: #FFFFFF;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
}
.AddClipart-content .New-Clipart-list-item-close i {
  font-size: 13px;
  padding: 6px;
}
.AddClipart-content .New-Clipart-list-item-close i::before {
  font-weight: 900 !important;
}
@media (min-width: 1500px) {
  .AddClipart-content .New-Clipart-list-item-close i {
    font-size: 17px;
  }
}

.DesignPanel-tools-packaging {
  padding: 20px 25px;
  height: auto;
}
.DesignPanel-tools-packaging-container {
  height: 100%;
  margin-top: 16px;
}

.PanelToggler {
  position: absolute;
  left: -25%;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.PanelToggler-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.PanelToggler-item-pointer {
  position: absolute;
  right: -15px;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  height: 70%;
  transform: rotate(0.5turn);
}
.PanelToggler-item-pointer img {
  width: 8px;
  height: 8px;
}
@media (min-width: 1500px) {
  .PanelToggler-item-pointer img {
    width: 10px;
    height: 10px;
  }
}
@media (max-width: 992px) {
  .PanelToggler-item-pointer {
    display: none;
  }
}
@media (min-width: 1500px) {
  .PanelToggler-item-pointer {
    height: 75%;
    right: -17px;
  }
}
.PanelToggler-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 10px;
  width: 37px;
  height: 37px;
  cursor: pointer;
}
.PanelToggler-item-icon:hover {
  background-color: rgba(57, 60, 65, 0.2) !important;
}
.PanelToggler-item-icon img {
  width: 80%;
  height: auto;
}
@media (min-width: 1500px) {
  .PanelToggler-item-icon img {
    width: 80%;
  }
}
@media (max-width: 992px) {
  .PanelToggler-item-icon img {
    width: 90%;
  }
}
@media (max-width: 992px) {
  .PanelToggler-item-icon {
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1500px) {
  .PanelToggler-item-icon {
    width: 40px;
    height: 40px;
  }
}
.PanelToggler-item-text {
  font-size: 10px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  color: #000000;
  margin-top: 3px;
  white-space: nowrap;
}
@media (max-width: 992px) {
  .PanelToggler-item-text {
    font-size: 9px;
    display: none;
  }
}
@media (min-width: 1500px) {
  .PanelToggler-item-text {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .PanelToggler-item {
    flex-direction: column;
    z-index: 100;
  }
}
.PanelToggler-divider div {
  position: relative;
  width: 3px;
  margin: 5px 0;
  height: 9vh;
}
@media (max-width: 992px) {
  .PanelToggler-divider div {
    width: 111px;
    height: 2px;
    top: 0;
    left: auto;
  }
}
@media (max-width: 576px) {
  .PanelToggler-divider div {
    width: 61px;
  }
}
@media (max-width: 992px) {
  .PanelToggler-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 57%;
    margin-left: -15px;
    margin-right: -15px;
    top: 20px;
  }
}
.PanelToggler .PastI {
  border: 1px solid #000000;
  background-color: #9C9C9C;
}
.PanelToggler .ActiveI {
  border: 2px solid #000000;
  background-color: #393C41;
}
.PanelToggler .FutureI {
  border: 1px solid #393C41;
  background-color: #ffffff;
}
.PanelToggler .PastD {
  background-color: #393C41;
}
.PanelToggler .FutureD {
  background-color: #9C9C9C;
}
@media (max-width: 1400px) {
  .PanelToggler {
    bottom: 90px;
  }
}
@media (max-width: 992px) {
  .PanelToggler {
    flex-direction: row;
    padding: 0;
    left: 0;
    right: 0;
    top: -13%;
    bottom: auto;
    z-index: 1000;
  }
}

.ShowPart {
  width: 75%;
  height: 100%;
}
@media (max-width: 1200px) {
  .ShowPart {
    width: 70%;
  }
}
@media (max-width: 992px) {
  .ShowPart {
    width: 100%;
    height: 45%;
  }
}
@media (min-width: 1500px) {
  .ShowPart {
    width: 79%;
  }
}

.CanvasPart {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.CanvasPart-test {
  position: absolute;
  left: 20px;
  top: 40px;
  font-size: 14px;
  z-index: 1000;
}
.CanvasPart .Logo {
  position: absolute;
  top: 30px;
  left: 50px;
  z-index: 1000;
}
.CanvasPart .Logo img {
  width: 90px;
  height: auto;
}
@media (max-width: 992px) {
  .CanvasPart .Logo img {
    width: 76px;
  }
}
@media (min-width: 1500px) {
  .CanvasPart .Logo img {
    width: 120px;
  }
}
@media (max-width: 992px) {
  .CanvasPart .Logo {
    left: 20px;
    top: 20px;
  }
}
.CanvasPart .Chat-min992 {
  position: absolute;
  left: 15px;
  bottom: 15px;
  cursor: pointer;
  z-index: 1000;
}
.CanvasPart .Chat-min992 .Chat {
  width: 40px;
  height: 40px;
}
.CanvasPart .Chat-min992 .Chat img {
  width: 100%;
  height: 100%;
}
@media (min-width: 1500px) {
  .CanvasPart .Chat-min992 .Chat {
    width: 55px;
    height: 55px;
    left: 20px;
    bottom: 20px;
  }
}
@media (max-width: 992px) {
  .CanvasPart .Chat-min992 {
    display: none;
  }
}
.CanvasPart .Canvas {
  width: 100%;
  height: 100%;
  position: relative;
}
.CanvasPart .Canvas img {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
  .CanvasPart .Canvas {
    width: 100%;
    height: 93%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}
.CanvasPart-controler {
  position: absolute;
  bottom: 35px;
  left: 42%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.CanvasPart-controler-item {
  border: 1px solid #5C5E62;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #FFFFFF;
}
.CanvasPart-controler-item:hover {
  border: 1px solid #5C5E62;
  background-color: #D7D8D9;
}
.CanvasPart-controler-item:focus {
  background-color: #393C41;
}
.CanvasPart-controler-item:hover .CanvasPart-controler-item-i {
  color: #000000 !important;
}
.CanvasPart-controler-item:focus .CanvasPart-controler-item-i {
  color: #FFFFFF !important;
}
.CanvasPart-controler-item i {
  font-size: 20px;
  color: #5C5E62;
}
.CanvasPart-controler-item i::before {
  font-weight: 900 !important;
}
@media (max-width: 992px) {
  .CanvasPart-controler-item i::before {
    font-weight: 500 !important;
  }
}
@media (max-width: 992px) {
  .CanvasPart-controler-item {
    width: 23px;
    height: 23px;
  }
}
@media (min-width: 1500px) {
  .CanvasPart-controler {
    bottom: 50px;
  }
}
@media (max-width: 992px) {
  .CanvasPart-controler {
    right: 15px;
    top: 40%;
    flex-direction: column;
    left: auto;
  }
}
@media (max-width: 992px) {
  .CanvasPart {
    border-radius: 16px;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1215686275);
    z-index: 1000;
  }
}